import './App.css';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './components/Loading';
import Product from './components/Product';
import Footer from './components/Footer';

function App() {

  const { isLoading, error } = useAuth0()

  const products = [
    {
      platform: 'Trading Offline',
      project: 'eTradeflow',
      url: process.env.REACT_APP_TERMINAL_BROKER,
      items: [
        'BOLETA',
        'GESTÃO DE RISCO',
        'GESTÃO DE BACKOFFICE',
        'ÍNDICES E BOOKS'
      ]
    },
    {
      platform: 'Trading Online',
      project: 'eTradeflow',
      url: process.env.REACT_APP_HOME_BROKER,
      items: [
        'NEGOCIAÇÃO EM TELA',
        'ENERGIA E I-RECS',
        'INFORMAÇÕES DE MERCADO',
      ]
    },
    {
      platform: 'Pós-Trading',
      project: 'eFinchain',
      url: process.env.REACT_APP_TURING,
      items: [
        'SEGUROS',
        'GARANTIAS FINANCEIRAS',
        'SERVIÇOS FINANCEIROS',
      ]
    }
  ]

  if(isLoading){
    return <Loading/>
  }

  if(error) return <div>Oops... {error.message}</div>

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center relative bg-[url('/public/assets/background.png')] bg-cover bg-center bg-no-repeat">
      <img src="/assets/logo.png" class="absolute h-full -right-1 opacity-80 z-0" alt="logo"/>
      <div className='flex flex-col w-full lg:w-3/5 xl:w-3/5 max-w-[1200px] items-center justify-center'>
        <div className='flex w-full items-end justify-end'>
          <img src="/assets/beenx.png" class="w-1/2 z-10" alt="Beenx"/>
        </div>
        <div className='relative flex items-top w-full justify-between'>
          {products.map(product =>(
            <Product
              product={product}
            />
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => (
    <Loading/>
  ),
  loginOptions:{
    connection: process.env.REACT_APP_AUTH0_CONNECTION
  }
});
