

function Product({product}) {

    return (
        <div className="lg:w-1/3 m-7 justify-center items-center">
        <a href={product?.url}>
          <div className='flex flex-col w-full min-h-[150px] p-10 bg-beenxPurple rounded hover:shadow-2xl justify-center items-center'>
            <span className='text-lg'>{product?.platform}</span>
          </div>
        </a>
        <div className='w-full justify-center items-center mt-10'>
          <ul className='list-none w-full text-beenxOrange'>
            {product?.items.map(item =>(
                <li className='mb-3 flex'><span className='text-lg drop-shadow-lg'>- {item}</span></li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  
  export default Product;
  