

function Footer() {

    return (
        <div class="absolute bottom-1 left-2">
            <span>© 2022 Beenx</span>
        </div>
    );
  }
  
  export default Footer;
  